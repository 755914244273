import * as React from "react"

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_services.module.scss"

import Pos from "src/images/pos-system.png"
import Intergration from "src/images/system-integration.png"
import Web from "src/images/web-system.png"

const ServicesPage = () => (
  <Layout>
    <Seo title="事業概要" />

    <div className="pageWrapper">
      <div className="pageTtl">
        <h1>事業概要</h1>
      </div>

      <div className="sectionWrapper">
        <div className={styles.serviceMenu}>
          <div className={styles.serviceIcon}>
            <img src={ Pos } alt="" />
            <p>POSシステム開発</p>
          </div>
          <p className={styles.txtContent}>
            POSシステムの豊富な経験と実績でお客様のご要望にお答えします。個別受託開発、パッケージ開発、パッケージOEM開発等、POSに関する開発はお任せ下さい。
          </p>
        </div>
        <hr className={styles.topBrdBlue} />
        <div className={styles.serviceMenu}>
          <div className={styles.serviceIcon}>
            <img src={ Intergration } alt="" />
            <p>システムインテグレーション</p>
          </div>
          <p className={styles.txtContent}>
            POSシステム、店舗情報システム、製造設備システム、計測制御システムなどのシステムインテグレーションを手掛けております。
          </p>
        </div>
        <hr className={styles.topBrdBlue} />
        <div className={styles.serviceMenu}>
          <div className={styles.serviceIcon}>
            <img src={ Web } alt="" />
            <p>WEBシステム</p>
          </div>
          <p className={styles.txtContent}>
            ショッピングシステム、顧客サービスシステム、予約システムなどサーバサイドアプリケーションの開発を手掛けております。
          </p>
        </div>
      </div>

      <hr />

      <h2 className={styles.faqTtl}>FAQ<span>よくあるご質問</span></h2>
      <div className={styles.faq}>
        <dl>
          <dt>スマートデバイス向けアプリの開発はおこなっていますか？</dt>
          <dd>Android 用 POS アプリの開発実績はございます。</dd>
        </dl>
        <dl>
          <dt>専門スタッフの派遣はありますか？</dt>
          <dd>
            ご指定の場所にエンジニアを派遣し弊社開発ソフトウェアの導入と機器の取り付け、簡単な操作説明などをおこないます。<br />
            ただし全国数か所に同システムを導入される場合には、派遣は初回のみとし他現地への導入はキッティングサービスにて対応させて頂く場合がございます。<br />
            （ダウンロード販売のソフトウェアは対象外となります）
          </dd>
        </dl>
        <dl>
          <dt>キッティングサービスはありますか？</dt>
          <dd>
            直ぐにお使い頂けるよう、納品前にパソコンへ弊社ソフトウェアの導入・各機器の設定・ネットワーク設定などをおこない、お客様への配送をおこなっています。<br />
            （ダウンロード販売のソフトウェアは対象外となります）
          </dd>
        </dl>
      </div>
    </div>
  </Layout>
)

export default ServicesPage
